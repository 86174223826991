import "@/styles/globals.css";
// import "@/styles/assetsFiles/sass/style.sass";
import '@/styles/phone-input.css'

import { FacebookPixels, GoogleScripts } from "@/components/analytics";
import Head from "next/head";
import { Fragment } from "react";
import generateUniqueEventID from "@/src/components/lib/generateUniqueEventID";
import { reportWebVitals } from "@/src/components/lib/webVitals";
import useMouseOver from "@/src/hooks/useMouseOver";

import { Inter } from "next/font/google";
// app comment
const inter = Inter({
  weight: ["400", "500", "600", "700"],
  style: ["normal"],
  subsets: ["latin"],
  display: "swap",
});

var shouldRenderScripts;
export default function App({ Component, pageProps }) {

  const isMouseHover = useMouseOver();
  shouldRenderScripts = isMouseHover;

  return (
    <Fragment>
            {shouldRenderScripts && (
        <>
         {/*} <FacebookPixels eventId={uniqueEventID} />*/}
          
        </>
      )}
      <Head>
        <meta charSet="utf-8" />
        <meta name="description" content />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />

        {/* Title */}
       
        <title>{process.env.NEXT_PUBLIC_TITLE}</title>
      </Head>


        <main className={inter.className}>
          <Component {...pageProps} />
        </main>
  
    </Fragment>
  );
}
export function reportWebVital(metric) {
  if (metric.label === "web-vital" && shouldRenderScripts) {
    reportWebVitals(metric);
  }
}
