import Script from "next/script";

export function reportWebVitals(metric) {
  console.log(metric);

  <Script strategy="lazyOnload" id="gtm-script">
    {`
                        window.gtag('event', {
                          event_category: 'web-vital',
                          value: Math.round(metric.name === 'CLS' ? metric.value * 1000 : metric.value), // values must be integers
                          event_label: metric.id, // id unique to current page load
                          non_interaction: true, // avoids affecting bounce rate.
                        }) 
                  `}
  </Script>;
}
