import { useEffect, useState } from "react";

const useMouseOver = () => {
  const [isPixelActive, setIsPixelActive] = useState(false);

  useEffect(() => {
    const handleMouseOver = () => {
      setIsPixelActive(true);
    };

    window.addEventListener("mouseover", handleMouseOver);

    return () => {
      window.removeEventListener("mouseover", handleMouseOver);
    };
  }, []); // Empty dependency array ensures the effect runs only once on mount

  return isPixelActive;
};

export default useMouseOver;
